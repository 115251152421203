<template>
  <div>
    <videoPlayer />
  </div>
</template>

<script>
import videoPlayer from '@/layouts/components/videoPlayer'
export default {
  name: 'VagsisNewLogScreen',
components: {
    videoPlayer
},
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>